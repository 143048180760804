/*
 * @Author: 李明明
 * @Date: 2022-01-04 10:44:37
 * @LastEditTime: 2022-01-04 14:44:44
 * @LastEditors: 李明明
 * @Description: 配置文件
 * @FilePath: /zs-query/src/main.ts
 * 代码版权方：'政府采购信息网'
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "normalize.css/normalize.css";
createApp(App).use(store).use(router).use(ElementPlus).mount("#app");
