<!--
 * @Author: 李明明
 * @Date: 2022-01-04 10:44:37
 * @LastEditTime: 2022-01-04 14:42:09
 * @LastEditors: 李明明
 * @Description: 
 * @FilePath: /zs-query/src/App.vue
 * 代码版权方：'政府采购信息网'
-->
<template>
  <router-view />
</template>

<style lang="scss">
</style>
