/*
 * @Author: 李明明
 * @Date: 2022-01-04 10:44:37
 * @LastEditTime: 2023-10-31 18:07:05
 * @LastEditors: 李明明
 * @Description:
 * @FilePath: /证书查询/zs-query/src/router/index.ts
 * 代码版权方：'政府采购信息网'
 */
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Layout",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../Layout/index.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/home/index.vue"),
      },
      {
        path: "/zs_list",
        name: "ZsList",
        component: () =>
          import(
            /* webpackChunkName: "zs_list" */ "../views/zs/zs_list/index.vue"
          ),
      },
      {
        path: "/shanxi_zs_list",
        name: "ShanxiZsList",
        component: () =>
          import(
            /* webpackChunkName: "zs_list" */ "../views/zs/shanxi_zs_list/index.vue"
          ),
      },
      {
        path: "/code_zs",
        name: "CodeZs",
        component: () =>
          import(
            /* webpackChunkName: "zs_list" */ "../views/zs/code_zs/index.vue"
          ),
      },
      {
        path: "/zs_center",
        name: "ZsCenter",
        component: () =>
          import(
            /* webpackChunkName: "zs_center" */ "../views/zs/zs_center/index.vue"
          ),
      },
      {
        path: "/zs_center_old",
        name: "ZsCenterOld",
        component: () =>
          import(
            /* webpackChunkName: "zs_center_old" */ "../views/zs/zs_center_old/index.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
